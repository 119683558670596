import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { StaticQuery } from "gatsby"

export default function AvatarAndSocial() {
  return (

    <StaticQuery query={
        graphql`
            query AvatarQuery {
                avatar: file(base: { eq: "harjas.png" }) {
                    publicURL
                }
            }`
        }
        
        render={
            data => (
                <div className="h-100 flex flex-col items-center space-y-8">
                    <div className="h-52 w-52 rounded-full border-2 border-black">
                    <img className="rounded-full shadow-xl" src={data.avatar.publicURL} alt="harjas' cartoon face"/>
                    </div>
                    <div className="flex flex-row lg:flex-col space-x-8 lg:space-x-0 lg:space-y-4">
                    <a className="flex lg:space-x-2" href="https://www.twitter.com/fruitcartrule" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faTwitter} size="lg" />
                        <div className="hidden lg:block">
                        @fruitcartrule
                        </div>
                    </a>
                    <a className="flex lg:space-x-2" href="https://www.instagram.com/fruitcartrule" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faInstagram} size="lg" />
                        <div className="hidden lg:block">
                        @fruitcartrule
                        </div>
                    </a>
                    <a className="flex lg:space-x-2" href="https://www.linkedin.com/in/harjas" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faLinkedinIn} size="lg" />
                        <div className="hidden lg:block">
                        @harjas
                        </div>
                    </a>
                    </div>
                </div>
            )
        }
    />
  )
}