import React from "react"

export default function Intro() {
  return (
    <div className="flex flex-col space-y-8 font-mont text-lg lg:text-lg leading-relaxed">
        <div className="flex flex-row">
            <span className="text-3xl mr-4" role="img" aria-label="direct hit emoji">🎯</span> 
            <span>
                I'm 
                <span>
                <span className="name cursor-pointer relative py-0.5 border-b-4 border-dotted border-blue-gray-700 text-blue-gray-700 font-bold"> 
                    <span> Harjas</span>
                </span>
                <span className="tooltip hidden absolute bg-white border-grey-100 px-4 py-2 text-sm">
                    (pronounced: <em>harj-us</em>)
                </span>.
                </span>
                <span> Nice to meet you! What brings you here? </span>
            </span>
        </div>
        <div className="flex flex-row">
            <div className="text-3xl mr-4"><span role="img" aria-label="coding emoji">👨🏽‍💻</span></div>
            <div className="flex flex-col space-y-2">
                <div className="">
                <span>
                    I am a Software Engineer, currently working at 
                    <span className="py-0.5 border-b-4 border-dotted border-blue-gray-700 text-blue-gray-700 font-bold">
                    <a href="https://www.etsy.com" target="_blank" rel="noopener noreferrer"> Etsy </a>
                    </span>
                </span> 
                on their Integrations Platform. <br></br>
                <span>
                    I previously worked at 
                    <span className="py-0.5 border-b-4 border-dotted border-blue-gray-700 text-blue-gray-700 font-bold">
                    <a href="https://www.grab.com" target="_blank" rel="noopener noreferrer"> Grab </a> 
                    </span>
                    and 
                    <span className="py-0.5 border-b-4 border-dotted border-blue-gray-700 text-blue-gray-700 font-bold">
                    <a href="https://www.expedia.com" target="_blank" rel="noopener noreferrer"> Expedia. </a>
                    </span> 
                </span>
                </div>
            </div>
        </div>
        <div className="flex flex-row">
            <div className="text-3xl mr-4"><span role="img" aria-label="mic emoji">🎤</span></div>
            <div className="">
                Sometimes, I tell  
                <span className="py-1 border-b-4 border-dotted border-blue-gray-700 text-blue-gray-700 font-bold">
                <a href="https://www.youtube.com/watch?v=nqBDDPl6yAk" target="_blank" rel="noopener noreferrer"> stories</a>.
                </span>
            </div>
        </div>

        <div className="flex flex-row">
            <div className="text-3xl mr-4"><span role="img" aria-label="dog emoji">🐶</span></div>
            <div className="">
                But, most of my time is spent trying to stop    
                <span className="py-1 border-b-4 border-dotted border-blue-gray-700 text-blue-gray-700 font-bold">
                <a href="https://www.instagram.com/p/BivxDdpH-v2/" target="_blank" rel="noopener noreferrer"> my dog </a>
                </span>
                from barking at UPS trucks.
            </div>
        </div>
    </div>
  )
}