import React from "react"

import AvatarAndSocial from '../components/home/avatar-and-social'
import Container from '../components/container'
import Title from '../components/common/title'

import './index.css';
import Intro from "../components/home/intro";

export default function Home() {
  return (
    <Container>
      <div className="w-full h-full lg:h-3/5 lg:w-3/5 flex flex-col lg:flex-row items-center lg:items-start lg:space-x-24">
        {/* show this title only on smaller viewports */}
        {/* <Title title="Hello, Internet!" smVisibility="block" emojiVisbility="hidden" /> */}
        <div className="my-6 lg:hidden flex justify-center text-3xl lg:text-5xl font-archivo">
            <span className="py-1.5 border-b-8 border-dashed border-blue-gray-700 text-blue-gray-700">
                Hello, Internet!
            </span>
        </div>
        
        {/* avatar and socials */}
        <AvatarAndSocial />
        
        {/* body */}
        <div className="w-4/5 mb-12 lg:w-full flex flex-col space-y-12">
          <Title title="Hello, Internet!" smVisibility="hidden"/>
          <Intro />
        </div>
      </div>
    </Container>
  )
}
