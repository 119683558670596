import React from "react"

export default function Title(props) {
    let lgVisbility = 'hidden';
    if (props.smVisibility === 'hidden') {
        lgVisbility = 'block';
    }
    return (
        <div className={`my-6 ${props.smVisibility} lg:${lgVisbility} flex justify-center text-3xl lg:text-5xl font-archivo`}>
            <span className="py-1.5 border-b-8 border-dashed border-blue-gray-700 text-blue-gray-700">
                {props.title}
            </span>
            <span className={`${props.emojiVisbility}`} role="img" aria-label="wave emoji">👋 </span>
        </div>
    )
}